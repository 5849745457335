<template>
  <b-card
    no-body
    :style="`height: ${componentOptions.cardHeight ? componentOptions.cardHeight : '60vh'};`"
  >
    <b-card-header>
      <b-card-title>Employment Status</b-card-title>
    </b-card-header>

    <b-card-body>
      <b-overlay :show="isLoading">
        <!-- apex chart -->
        <vue-apex-charts
          type="donut"
          height="220"
          class="my-1"
          :options="employmentStatus.chartOptions"
          :series="employmentStatus.series"
        />

        <!-- chart info -->
        <div
          v-for="(data, key, index) in chartInfo"
          :key="key"
          class="d-flex justify-content-between"
          :class="index === Object.keys(chartInfo).length - 1 ? '' : 'mb-1'"
        >
          <div class="series-info d-flex align-items-center">
            <feather-icon
              icon="CircleIcon"
              size="16"
              :class="
                key === 'unemployed_for_less_than_6_months'
                  ? 'text-dark'
                  : key === 'unemployed_for_more_than_6_months'
                    ? 'text-danger'
                    : 'text-success'
              "
            />
            <span class="font-weight-bold text-capitalize ml-75">{{
              data.title
            }}</span>
          </div>

          <span>{{ data.value }}</span>
        </div>
      </b-overlay>
    </b-card-body>
  </b-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

export default {
  components: {
    VueApexCharts,
  },
  props: ['componentOptions', 'reportData', 'section'],
  data() {
    return {
      isLoading: false,
      chartInfo: {
        unemployed_more_than_6_month: {
          value: 0,
          title: 'Unemployed > 6 Months',
        },
        unemployed_less_than_6_month: {
          value: 0,
          title: 'Unemployed < 6 Months',
        },
        employed: {
          value: 0,
          title: 'employed',
        },
      },
      employmentStatus: {
        series: [],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          labels: [
            'Employed',
            'Unemployed < 6 months',
            'Unemployed > 6 months',
          ],
          colors: [
            $themeColors.success,
            $themeColors.primary,
            $themeColors.danger,
          ],
          dataLabels: {
            enabled: false,
          },
          legend: { show: false },
          comparedResult: [2, -3, 8],
          stroke: { width: 0 },
        },
      },
    }
  },

  watch: {
    reportData(newVal, oldVal) {
     this.updateData(this.reportData)
    },
  },
  mounted() {
    if (Object.keys(this.reportData).length > 0) {
      this.updateData(this.reportData)
    }
  },
  methods: {
    updateData(newVal) {
      const chartData = newVal.statistics.charts[this.section].employment_status

      this.chartInfo = {
        employed: {
          value: chartData.employed,
          title: 'employed',
        },
        unemployed_for_less_than_6_months: {
          value: chartData.unemployed_for_less_than_6_months,
          title: 'Unemployed < 6 months',
        },
        unemployed_for_more_than_6_months: {
          value: chartData.unemployed_for_more_than_6_months,
          title: 'Unemployed > 6 months',
        },
      }
      this.employmentStatus = {
        series: [
          // chartData['Unemployed > 6 Months'],
          // chartData['Unemployed < 6 Months'],
          // chartData['Unemployed'],
          // chartData['Self-employed'],
          chartData.employed,
          chartData.unemployed_for_less_than_6_months,
          chartData.unemployed_for_more_than_6_months,
        ],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          labels: [
            'Employed',
            'Unemployed < 6 months',
            'Unemployed > 6 months',
          ],
          colors: [
            $themeColors.success,
            $themeColors.dark,
            $themeColors.danger,
          ],
          dataLabels: {
            enabled: false,
          },
          legend: { show: false },
          comparedResult: [2, -3, 8],
          stroke: { width: 0 },
        },
      }
    },
  },
}
</script>
