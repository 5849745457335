<template>
  <div class="w-100 h-100">
    <custom-analytic-card
      :title="title"
    >
      <table class="custom-table">
        <tbody v-if="$isNotEmpty(reportData.tracking)">
          <tr>
            <td>Average Session Duration</td>
            <td class="font-weight-bolder text-right">
              {{ reportData.tracking.session_duration_average }}
            </td>
          </tr>
          <tr>
            <td>Total 'Apply Now' button clicked</td>
            <td class="font-weight-bolder text-right">
              {{ $thousandSeparated(reportData.tracking.form_abandon_rate.total_form_count) }}
            </td>
          </tr>
          <tr>
            <td>Submitted Application Form</td>
            <td
              class="font-weight-bolder"
            >
              <div class="d-flex justify-content-between">
                <div>
                  ( {{ parseFloat(reportData.tracking.form_abandon_rate.form_submit_percentage).toFixed(2) }}% )
                </div>
                <div>
                  {{ $thousandSeparated(reportData.tracking.form_abandon_rate.form_submit_count) }}
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>Abandoned Application Form</td>
            <td
              class="font-weight-bolder"
            >
              <div class="d-flex justify-content-between">
                <div>
                  ( {{ parseFloat(reportData.tracking.form_abandon_rate.form_not_submit_percentage).toFixed(2) }}% )
                </div>
                <div>
                  {{ $thousandSeparated(reportData.tracking.form_abandon_rate.form_not_submit_count) }}
                </div>
              </div>
            </td>
          </tr>

          <!-- TOURISM PAGE VISIT -->
          <tr>
            <td>Event Page Visit</td>
            <td
              class="font-weight-bolder"
            >
              <div class="d-flex justify-content-end">
                <div>
                  {{ reportData.tracking.tourism_event.absolute_tourism_event_page_visit }}
                </div>
              </div>
            </td>
          </tr>

          <!-- TOURISM VIEW MORE CLICK -->
          <tr>
            <td>Event Jobs Click</td>
            <td
              class="font-weight-bolder"
            >
              <div class="d-flex justify-content-end">
                <div>
                  {{ reportData.tracking.tourism_event.view_more_click_count }}
                </div>
              </div>
            </td>
          </tr>

          <!-- TOURISM APPLY NOW CLICK -->
          <tr>
            <td>Tourism Event Apply Now Click</td>
            <td
              class="font-weight-bolder"
            >
              <div class="d-flex justify-content-end">
                <div>
                  {{ reportData.tracking.tourism_event.form_click_count }}
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </custom-analytic-card>
  </div>
</template>

<script>
import CustomAnalyticCard from '../CustomAnalyticCard.vue'

export default {
  components: {
    CustomAnalyticCard,
  },
  props: {
    width: {
      type: [String, Number],
      default: '100%',
    },
    height: {
      type: [String, Number],
      default: '350',
    },
  },
  data() {
    return {
      isLoading: false,
      title: 'Tracking Analytics',
      reportData: {},
      colors: [
        this.$themeColors.primary,
        this.$themeColors.dark,
      ],
    }
  },
  created() {
    this.initComponent()
  },
  methods: {
    initComponent() {
      const reportId = this.$route.params.report_name.split('__')[1]

      this.isLoading = true
      this.$http
        .post(`/api/event-reports/analytics`, {
          form_data: {
            fetch_data: ['tracking'],
            report_id: reportId,
          }
        })
        .then(response => {
          const { reports } = response.data
          this.$set(this.reportData, 'tracking', reports['tracking'])

          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
        })
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
