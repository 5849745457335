<template>
  <div class="w-100 h-100">
    <statistic-card-horizontal
      :is-loading="isLoading"
      icon="DribbbleIcon"
      :statistic="chartData"
      :statistic-title="title"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      title: 'Average Session Duration',
      chartData: 0,
      colors: [
        this.$themeColors.primary,
        this.$themeColors.dark,
      ],
    }
  },
  created() {
    this.initComponent()
  },
  methods: {
    initComponent() {
      const reportId = this.$route.params.report_name.split('__')[1]

      this.isLoading = true
      this.$http
        .post(`/api/event-reports/analytics`, {
          form_data: {
            fetch_data: ['session_duration_average'],
            report_id: reportId,
          }
        })
        .then(response => {
          const { reports } = response.data
          this.chartData = reports['session_duration_average']

          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
        })
    }
  },
}
</script>

<style>

</style>