<template>
  <div class="w-100 h-100">
    <custom-analytic-card
      :title="title"
    >
      <table class="custom-table">
        <thead>
          <tr>
            <td>Page</td>
            <td class="text-right">Unique</td>
            <td class="text-right">Absolute</td>
          </tr>
        </thead>
        <tbody v-if="$isNotEmpty(chartData)">
          <tr
            v-for="pageVisit, key in chartData.page_visit_unique_and_absolute"
            :key="key.key"
          >
            <td>{{ key }}</td>
            <td class="font-weight-bolder text-right">
              {{ $thousandSeparated(pageVisit.unique) }}
            </td>
            <td class="font-weight-bolder text-right">
              {{ $thousandSeparated(pageVisit.absolute) }}
            </td>
          </tr>

          <tr class="row-summary">
            <td><b>TOTAL</b></td>
            <td class="text-right">
              {{
                $thousandSeparated(
                  Object.values(chartData.page_visit_unique_and_absolute).map(item => item.unique).reduce((sum, count) => sum + count, 0)
                )
              }}
            </td>
            <td class="text-right">
              {{
                $thousandSeparated(
                  Object.values(chartData.page_visit_unique_and_absolute).map(item => item.absolute).reduce((sum, count) => sum + count, 0)
                )
              }}
            </td>
          </tr>
        </tbody>

      </table>
    </custom-analytic-card>
  </div>
</template>

<script>
import CustomAnalyticCard from '../CustomAnalyticCard.vue'

export default {
  components: {
    CustomAnalyticCard,
  },
  props: {
    width: {
      type: [String, Number],
      default: '100%',
    },
    height: {
      type: [String, Number],
      default: '350',
    },
  },
  data() {
    return {
      isLoading: false,
      title: 'Page Visits',
      chartData: {},
      colors: [
        this.$themeColors.primary,
        this.$themeColors.dark,
      ],
    }
  },
  created() {
    this.initComponent()
  },
  methods: {
    initComponent() {
      const reportId = this.$route.params.report_name.split('__')[1]

      this.isLoading = true
      this.$http
        .post(`/api/event-reports/analytics`, {
          form_data: {
            fetch_data: ['page_visit_unique_and_absolute'],
            report_id: reportId,
          }
        })
        .then(response => {
          const { reports } = response.data
          this.$set(this.chartData, 'page_visit_unique_and_absolute', reports['page_visit_unique_and_absolute'])

          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
        })
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
