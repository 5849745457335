<template>
  <div class="w-100 h-100">
    <custom-analytic-card
      :title="title"
    >
      <table class="custom-table">
        <thead>
          <tr>
            <td>Source</td>
            <td>Medium</td>
            <td>Campaign</td>
            <td>Count</td>
          </tr>
        </thead>

        <tbody v-if="$isNotEmpty(reportData)">
          <tr
            v-for="utm, key, index in reportData.source_of_visitor_utm"
            :key="utm.key"
          >
            <td>{{ utm.utm_source }}</td>
            <td>{{ utm.utm_medium }}</td>
            <td>{{ utm.utm_campaign }}</td>
            <td class="font-weight-bolder">
              {{ utm.count }}
            </td>
          </tr>
        </tbody>
      </table>
    </custom-analytic-card>
  </div>
</template>

<script>
import CustomAnalyticCard from '../CustomAnalyticCard.vue'

export default {
  components: {
    CustomAnalyticCard,
  },
  props: {
    width: {
      type: [String, Number],
      default: '100%',
    },
    height: {
      type: [String, Number],
      default: '350',
    },
  },
  data() {
    return {
      isLoading: false,
      title: 'Source of Visitors (UTM)',
      reportData: {},
      colors: [
        this.$themeColors.primary,
        this.$themeColors.dark,
      ],
    }
  },
  created() {
    this.initComponent()
  },
  methods: {
    initComponent() {
      const reportId = this.$route.params.report_name.split('__')[1]

      this.isLoading = true
      this.$http
        .post(`/api/event-reports/analytics`, {
          form_data: {
            fetch_data: ['source_of_visitor_utm'],
            report_id: reportId,
          }
        })
        .then(response => {
          const { reports } = response.data
          this.$set(this.reportData, 'source_of_visitor_utm', reports['source_of_visitor_utm'])

          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
        })
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
