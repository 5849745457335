<template>
  <div class="w-100 h-100">
    <PieChart
      :is-loading="isLoading"
      :chart-data="chartData"
      :title="title"
      :colors="colors"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      title: 'Gender',
      chartData: {},
      colors: [
        '#19a8e6',
        '#ff3030',
      ],
    }
  },
  created() {
    this.initComponent()
  },
  methods: {
    initComponent() {
      const reportId = this.$route.params.report_name.split('__')[1]

      this.isLoading = true
      this.$http
        .post(`/api/event-reports/analytics`, {
          form_data: {
            fetch_data: ['gender_chart'],
            report_id: reportId,
          }
        })
        .then(response => {
          const { reports } = response.data
          this.chartData = {}
          Object.entries(reports['gender_chart']).forEach(([key, value], index) => {
            this.chartData[this.$capitalizeString(this.$removeUnderscore(key))] = value
          })

          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
        })
    }
  },
}
</script>

<style>

</style>