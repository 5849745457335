<template>
  <div class="w-100 h-100">
    <LineChart
      :is-loading="isLoading"
      :chart-data="chartData"
      :title="title"
      :colors="colors"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      title: 'Active Job - 30 days',
      chartData: {},
      colors: [
        this.$themeColors.primary,
        this.$themeColors.dark,
      ],
    }
  },
  created() {
    this.initComponent()
  },
  methods: {
    initComponent() {
      const reportId = this.$route.params.report_name.split('__')[1]

      this.isLoading = true
      this.$http
        .post(`/api/event-reports/analytics`, {
          form_data: {
            fetch_data: ['job_applications_chart'],
            report_id: reportId,
          }
        })
        .then(response => {
          const { reports } = response.data
          this.chartData = {
            //* 'Name of the line and user hover the tick': dict with date time as the key to act as the x-axis labeling {'2024-93': 3}
            'Job Application(s)': reports['job_applications_chart'],
          }

          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
        })
    }
  },
}
</script>

<style>

</style>