<template>
  <b-card
    no-body
    style="height: 60vh"
  >
    <b-card-header class="align-items-baseline">
      <div>
        <b-card-title class="mb-25">
          {{ title }}
        </b-card-title>
      </div>
    </b-card-header>

    <b-card-body>
      <b-overlay
        :show="isLoading"
        class="h-100"
      >
        <b-table
          striped
          responsive
          :items="data"
          :fields="fields"
        >
          <template #cell(company_name)="data">
            <p>{{ data.value }}</p>
          </template>
          <template
            v-if="whichPage === 'pre-event'"
            #cell(reasons)="data"
          >
            <p
              v-for="item in data.value.split('\n')"
              :key="item.id"
            >
              <feather-icon
                icon="PlusIcon"
                size="12"
                class="text-primary"
              />
              {{ item }}
            </p>
          </template>

          <template
            v-else
            #cell(waiting_time)="data"
          >
            <p>
              {{ data.value }}
            </p>
          </template>
        </b-table>
      </b-overlay>
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Popular Companies',
    },
    data: {
      type: Array,
      default: () => [],
    },
    whichPage: {
      type: String,
      default: 'pre-event',
    },
  },
  data() {
    return {
      isLoading: false,
      fields: ['company_name', 'reasons', 'waiting_times'],
    }
  },
  watch: {
    whichPage() {
      if (this.whichPage === 'pre-event') {
        this.fields = ['company_name', 'reasons']
      } else {
        this.fields = ['company_name', 'waiting_times']
      }
    },
  },
  mounted() {
    this.isLoading = false
    if (this.whichPage === 'pre-event') {
      this.fields = ['company_name', 'reasons']
    } else {
      this.fields = ['company_name', 'waiting_times']
    }
  },
}
</script>

<style></style>
